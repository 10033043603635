import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Typography from '@mui/material/Typography';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CalendarViewSensorArray from './CalendarViewSensorArray';
import {
  getActiveMarker,
  getCurrentLocation,
  getHighlightedItem,
  getLocationsById,
  getSelectedEndDate,
  getSelectedHours,
  getSelectedSensorIds,
  getSelectedStartDate,
  getSensorsById,
} from '../../state/selectors';
import { VarName } from '../../services/api';
import {
  ValueType,
  HeatmapHoverSelection,
  CalendarSelectionType,
  SensorFilteredStats,
  SensorAllStats,
  PlotType,
} from './helpers';
import { ActiveItem } from '../../state/types';
import { setSelectedSensors } from '../../state/actions';
import CalendarViewPlot from '../Plots/HeatMap/CalendarViewPlot';
import WeekViewPlot from '../Plots/HeatMap/WeekViewPlot';
import { varNameDetails } from '../../utils/varNames';
import LoaderWithBackdrop from '../HelperComponents/LoaderWithBackdrop';
import { combineStats, Stats } from '../../utils/statistics';

dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);

interface CalendarViewDataContainerProps {
  sensorStats: Map<string, SensorAllStats>;
  sensorFilteredStats: Map<string, SensorFilteredStats>;
  heatmapHoverSelection: HeatmapHoverSelection | undefined;
  setHeatmapHoverSelection: (value: HeatmapHoverSelection | undefined) => void;
  selectionType: CalendarSelectionType;
  downloadData: boolean;
  setDownloadData: (value: boolean) => void;
  loading: boolean;
}

function CalendarViewDataContainer({
  sensorStats,
  sensorFilteredStats,
  heatmapHoverSelection,
  setHeatmapHoverSelection,
  selectionType,
  downloadData,
  setDownloadData,
  loading,
}: CalendarViewDataContainerProps): JSX.Element {
  const dispatch = useDispatch();
  const highlightedItem = useSelector(getHighlightedItem);
  const activeMarker = useSelector(getActiveMarker);
  const currentLocation = useSelector(getCurrentLocation);
  const selectedSensors = useSelector(getSelectedSensorIds);
  const [hoveredSensor, setHoveredSensor] = useState<ActiveItem>();
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);
  const sensorsById = useSelector(getSensorsById);
  const locationsById = useSelector(getLocationsById);
  const hoursSelection = useSelector(getSelectedHours);

  const sensorIdsWithData = Array.from(sensorStats.keys());

  useEffect(() => {
    setHoveredSensor(undefined);
    setHeatmapHoverSelection(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker, currentLocation]);

  // selectedSensors that we actually have data for
  const addedSensors = useMemo(() => {
    const activeSensors = selectedSensors.filter((selectedId) =>
      sensorIdsWithData.some((id) => id === selectedId)
    );
    return activeSensors;
  }, [sensorIdsWithData, selectedSensors]);

  useEffect(() => {
    if (highlightedItem.id && highlightedItem.varName)
      setHoveredSensor({ id: highlightedItem.id, varName: highlightedItem.varName });
    else setHoveredSensor(undefined);
  }, [highlightedItem]);

  const heatmapSensorIds = useMemo(() => {
    let sensors: string[] = [];
    if (addedSensors.length > 0) sensors = addedSensors;
    else if (hoveredSensor) sensors = [hoveredSensor.id];
    return sensors;
  }, [hoveredSensor, addedSensors]);

  const highlightedItemStats = sensorStats.get(highlightedItem.id ?? 'NONE')?.stats ?? undefined;
  const selectedSensorStats = useMemo(() => {
    const statsArray: Stats[] = [];
    let output: Stats = {
      bandTimes: new Map<string, number>(),
      totalTime: 0,
      maxMinAvg: { max: NaN, min: NaN, mean: NaN },
    };
    if (selectedSensors.length > 0) {
      selectedSensors.forEach((id) => {
        const s = sensorStats.get(id)?.stats;
        if (s !== undefined) statsArray.push(s);
      });
      output = combineStats(statsArray);
    }
    return output;
  }, [selectedSensors, sensorStats]);
  const totalSensorStats = useMemo(() => {
    let output: Stats = {
      bandTimes: new Map<string, number>(),
      totalTime: 0,
      maxMinAvg: { max: NaN, min: NaN, mean: NaN },
    };
    if (sensorStats.size > 0) {
      const statsArray = Array.from(sensorStats.values(), (value) => value.stats);
      output = combineStats(statsArray);
    }
    return output;
  }, [sensorStats]);

  let combinedSensorStats: Stats = totalSensorStats;
  if (addedSensors.length > 0) combinedSensorStats = selectedSensorStats;
  else if (highlightedItemStats !== undefined) combinedSensorStats = highlightedItemStats;

  const sensorSelectionLabel = useMemo(() => {
    let label = `${locationsById.get(currentLocation)?.name ?? ''} (${sensorStats.size} ${
      varNameDetails[activeMarker].label
    } sensors)`;
    if (heatmapSensorIds.length > 0) {
      if (heatmapSensorIds.length === 1)
        label = `${sensorsById.get(heatmapSensorIds[0])?.name ?? heatmapSensorIds[0]} (${
          varNameDetails[activeMarker].label
        })`;
      else
        label = `${heatmapSensorIds.length} selected ${varNameDetails[activeMarker].label} sensors`;
    }
    return label;
  }, [
    locationsById,
    currentLocation,
    heatmapSensorIds,
    sensorsById,
    activeMarker,
    sensorStats.size,
  ]);

  const clearSensorHandler = () => {
    dispatch(setSelectedSensors([]));
  };

  // XXX TODO Do we need the utl type? Could just be static?
  const valueType = activeMarker === VarName.MotionEvent ? ValueType.utl : ValueType.avg;

  const valuesLabel = useMemo(() => {
    const hasTotValue = Array.from(sensorStats.values()).some(
      (s) => s.stats.totalAdded !== undefined
    );

    let label = `${hasTotValue ? 'Totals' : 'Averages'} `;
    if (heatmapHoverSelection) {
      const { date, weekWithStart, dayOfWeek, hour, summaryType } = heatmapHoverSelection;
      if (summaryType) {
        if (summaryType === PlotType.min) label = 'Minimum values ';
        else if (summaryType === PlotType.max) label = 'Maximum values ';
        else if (summaryType === PlotType.avg) label = 'Averages ';
        else label = `${summaryType}s `;
      }
      if (date) {
        return label.concat('for ').concat(dayjs(date).format('MMM DD YYYY'));
      }
      if (weekWithStart) {
        return label.concat('for week starting ').concat(dayjs(date).format('MMM DD YYYY'));
      }
      if (dayOfWeek !== undefined) {
        return label
          .concat('for all ')
          .concat(dayjs(new Date()).day(dayOfWeek).format('dddd'))
          .concat('s');
      }
      if (hour !== undefined) {
        return label.concat(
          `for ${hour.toFixed().padStart(2, '0')}:00-${(hour + 1).toFixed().padStart(2, '0')}:00 ${
            !hoursSelection.selectHours || hoursSelection.includeWeekends
              ? 'on all days'
              : 'Mon-Fri'
          }`
        );
      }
    }
    label = label.concat(
      `${dayjs(startDate).format('MMM DD')} - ${dayjs(endDate).format('MMM DD YYYY')}`
    );

    return label;
  }, [sensorStats, startDate, endDate, heatmapHoverSelection, hoursSelection]);

  const getValuesLabelWithValue = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const plotLabel = parseFloat(heatmapHoverSelection?.hoverValue).toFixed(1);
    const metric = varNameDetails[activeMarker].metric ?? '';
    const label = heatmapHoverSelection
      ? `${valuesLabel} (All ${plotLabel}${metric})`
      : valuesLabel;
    return label;
  };

  const valuesRange: [number, number] | undefined =
    totalSensorStats?.totalAdded !== undefined && totalSensorStats.values
      ? [0, Math.max(...totalSensorStats.values.totVals)]
      : [totalSensorStats.maxMinAvg.min, totalSensorStats.maxMinAvg.max];

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 0 10px 10px',
        }}
      >
        <Typography variant="h6" style={{ marginTop: '10px' }}>
          {sensorSelectionLabel}
        </Typography>
        {addedSensors.length > 0 && (
          <IconButton
            onClick={() => clearSensorHandler()}
            style={{ padding: '0', marginLeft: '10px' }}
          >
            <Tooltip title="Remove all locked sensors">
              <RemoveCircleIcon color="error" />
            </Tooltip>
          </IconButton>
        )}
      </div>
      <div>
        <div style={{ position: 'relative' }}>
          {loading && <LoaderWithBackdrop />}
          {selectionType === CalendarSelectionType.week ? (
            <WeekViewPlot
              combinedSensorStats={combinedSensorStats}
              varName={activeMarker}
              valueType={valueType}
              setHoveredPlot={setHeatmapHoverSelection}
              downloadData={downloadData}
              setDownloadData={setDownloadData}
              sourceLabel={sensorSelectionLabel.replaceAll(' ', '-')}
            />
          ) : (
            <CalendarViewPlot
              combinedSensorStats={combinedSensorStats}
              varName={activeMarker}
              valueType={valueType}
              setHoveredPlot={setHeatmapHoverSelection}
              downloadData={downloadData}
              setDownloadData={setDownloadData}
              sourceLabel={sensorSelectionLabel.replaceAll(' ', '-')}
            />
          )}
        </div>
        <Typography variant="h6" style={{ margin: '10px' }}>
          {getValuesLabelWithValue()}
        </Typography>

        {sensorFilteredStats.size === 0 && (
          <Alert severity="error" style={{ margin: '1rem 0' }}>
            No sensor data found
          </Alert>
        )}
        <div>
          <CalendarViewSensorArray
            sensorIds={sensorIdsWithData}
            sensorFilteredStats={sensorFilteredStats}
            valuesRange={valuesRange}
          />
        </div>
      </div>
    </>
  );
}

export default CalendarViewDataContainer;
