import React from 'react';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Plot from './PlotlyCustom';
import { VarName } from '../../utils/varNames';
import { motionUtilisationBands, varNameBandParams } from '../../utils/dataBandParams';
import { Stats } from '../../utils/statistics';
import { themeProps } from '../../styles/theme';

interface PlotProps {
  data: Stats;
  sensorName: string;
  varName: VarName;
  xRange?: [number, number];
}

export function InlineBarPlot({ data, sensorName, varName, xRange }: PlotProps): JSX.Element {
  const theme = useTheme();
  const inlinePlot = true;
  const isTransparentBg = true;
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultWidth = isMobileView ? 250 : 400;

  let plotData: Plotly.Data[] = [];
  const { bandTimes } = data;
  let bandDetails = varNameBandParams[varName];
  const isUtl = varName === VarName.MotionEvent && data.utl !== undefined;
  if (isUtl) {
    bandDetails = motionUtilisationBands;
  }
  if (bandDetails && bandTimes) {
    const bandTraces = bandDetails.map((band) => {
      const pct = bandTimes.get(band.label) ?? 0;
      const trace: Plotly.Data = {
        y: [sensorName],
        x: [pct],
        name: sensorName,
        type: 'bar',
        orientation: 'h',
        marker: {
          color: band.color,
          width: 1,
        },
      };
      return trace;
    });
    plotData = bandTraces;
  }

  return (
    <Plot
      data={plotData}
      layout={{
        barmode: 'stack',
        font: { family: themeProps.fontFamily.body },
        width: inlinePlot ? defaultWidth : undefined,
        height: inlinePlot ? 40 : 200,
        autosize: true,
        // Set margins to maximise plot area (need to use automargin on axes)
        margin: {
          l: 5,
          r: 5,
          b: 5,
          t: 5,
          pad: 0,
        },
        showlegend: false,
        dragmode: 'pan',
        xaxis: {
          automargin: true,
          range: xRange,
          showticklabels: !inlinePlot,
          showgrid: !inlinePlot,
          showline: !inlinePlot,
          tickfont: {
            color: theme.palette.text.primary,
          },
          gridcolor: alpha(theme.palette.text.primary, 0.1),
        },
        yaxis: {
          automargin: true,
          fixedrange: true, // Disable pan/zoom
          showticklabels: !inlinePlot,
          showgrid: !inlinePlot,
          showline: !inlinePlot,
          tickfont: {
            color: theme.palette.text.primary,
          },
          titlefont: {
            color: theme.palette.text.primary,
          },
          gridcolor: alpha(theme.palette.text.primary, 0.1),
        },
        plot_bgcolor: isTransparentBg ? 'rgba(0, 0, 0, 0)' : theme.palette.primary.main,
        paper_bgcolor: isTransparentBg ? 'rgba(0, 0, 0, 0)' : theme.palette.primary.main,
      }}
      config={{ displayModeBar: false, staticPlot: true }}
      useResizeHandler
    />
  );
}

InlineBarPlot.defaultProps = {
  xRange: undefined,
};

export default InlineBarPlot;
