import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { SensorLatest } from '../../services/api';
import {
  getActiveMarker,
  getHighlightedItem,
  getSelectedSensorIds,
  getSensorsById,
  getSortBy,
} from '../../state/selectors';
import { getBandColor, getDataString, sortSensors, SensorFilteredStats } from './helpers';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/calendarView';
import { setHighlightedItem, setSelectedSensors, setSortBy } from '../../state/actions';
import BaseArraySortMenu, { SortPropertyType } from '../HelperComponents/BaseArraySortMenu';
import { varNameDetails } from '../../utils/varNames';
import { SortType } from '../../state/types';
import InlineBarPlot from '../Plots/InlineBarPlot';
import HistogramPlot from '../Plots/HistogramPlot';
import ReportsAddCircleIcon from '../../styles/icons/ReportsAddCircleIcon';
import BandValueCheckbox from '../HelperComponents/BandValueCheckbox';
import { themeProps } from '../../styles/theme';

interface CalendarViewSensorArrayProps {
  sensorIds: string[];
  sensorFilteredStats: Map<string, SensorFilteredStats>;
  valuesRange: [number, number];
}

function CalendarViewSensorArray({
  sensorIds,
  sensorFilteredStats,
  valuesRange,
}: CalendarViewSensorArrayProps): JSX.Element {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sortBy = useSelector(getSortBy);
  const highlightedItem = useSelector(getHighlightedItem);
  const activeMarker = useSelector(getActiveMarker);
  const selectedSensorIds = useSelector(getSelectedSensorIds);
  const sensorsById = useSelector(getSensorsById);
  const [showHistogram, setShowHistogram] = useState(true);

  const sensors = sensorIds.map((id) => sensorsById.get(id) ?? ({ id } as SensorLatest));

  useEffect(() => {
    const defaultSort: SortType = { property: 'name', ascending: true };
    if (sortBy !== defaultSort) dispatch(setSortBy(defaultSort));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedSensors = useMemo(
    () => sortSensors(sensors, sortBy, sensorFilteredStats),
    [sensors, sortBy, sensorFilteredStats]
  );

  const mouseEnter = (id: string) => {
    dispatch(setHighlightedItem({ id, varName: activeMarker, source: 'dashboardPanel' }));
  };

  const mouseLeave = () => {
    dispatch(setHighlightedItem({ id: '' }));
  };

  const selectSensorHandler = (id: string) => {
    const sensorsList = [...selectedSensorIds];
    const isSelectedSensor = sensorsList.some((sensor) => sensor === id);
    if (isSelectedSensor)
      dispatch(setSelectedSensors(sensorsList.filter((sensor) => sensor !== id)));
    else {
      sensorsList.push(id);
      dispatch(setSelectedSensors(sensorsList));
    }
  };

  return (
    <div>
      <List>
        <ListItem style={{ padding: '0 8px' }}>
          <Grid container spacing={1}>
            <Grid item sm={1} xs={2} style={{ textAlign: 'center' }}>
              <Tooltip title="Add to compare" placement="top">
                <div>
                  <ReportsAddCircleIcon />
                </div>
              </Tooltip>
            </Grid>
            <Grid item sm={6} xs={6} style={{ textAlign: 'end' }}>
              <Button
                onClick={() => setShowHistogram(!showHistogram)}
                variant={themeProps.btnVariant.default}
              >
                Toggle Histogram
              </Button>
            </Grid>
            <Grid item sm={5} xs={4} style={{ textAlign: 'end' }}>
              <BaseArraySortMenu sortProperties={[SortPropertyType.name, SortPropertyType.value]} />
            </Grid>
          </Grid>
        </ListItem>
        {sortedSensors.length > 0 ? (
          sortedSensors?.map((sensor) => {
            const summaryValue = sensorFilteredStats.get(sensor.id)?.summaryValue;
            const dataValueString = getDataString(summaryValue, activeMarker);
            const color = getBandColor(summaryValue, activeMarker);
            const isSelectedSensor = selectedSensorIds.some((id) => id === sensor.id);
            const sensorStats = sensorFilteredStats.get(sensor.id)?.stats;
            let inlineValues;
            if (sensorStats?.totalAdded !== undefined) {
              inlineValues = sensorStats?.values?.totVals;
            } else if (sensorStats?.utl !== undefined) {
              inlineValues = sensorStats?.values?.utlVals.map((v) => v * 100);
            } else {
              inlineValues = sensorStats?.values?.avgVals;
            }
            return (
              <ListItem key={sensor.id} style={{ padding: '4px' }}>
                <ListItemButton
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor:
                      sensor.id === highlightedItem.id || (!highlightedItem.id && isSelectedSensor)
                        ? `${color}66`
                        : theme.palette.primary.light,
                    borderRadius: '8px',
                    padding: '5px',
                  }}
                  onClick={() => selectSensorHandler(sensor.id)}
                  onMouseEnter={() => mouseEnter(sensor.id)}
                  onMouseLeave={mouseLeave}
                  onFocus={() => mouseEnter(sensor.id)}
                  onBlur={mouseLeave}
                >
                  <Grid container spacing={1}>
                    <Grid item sm={1} xs={2} style={{ textAlign: 'center' }}>
                      <BandValueCheckbox sensorId={sensor.id} sensorValue={summaryValue} />
                    </Grid>
                    <Grid item sm={2} xs={10} style={{ alignSelf: 'center' }}>
                      <Typography variant="body1">{sensor.name}</Typography>
                    </Grid>
                    <Grid item sm={7} xs={8} style={{ alignSelf: 'center', textAlign: 'center' }}>
                      {showHistogram ? (
                        <HistogramPlot
                          values={inlineValues !== undefined ? inlineValues : []}
                          sensorName={sensor.name ?? 'UNKNOWN'}
                          varName={activeMarker}
                          xRange={valuesRange}
                        />
                      ) : (
                        sensorStats &&
                        !sensorStats.bandTimes?.has('Unknown') && (
                          <InlineBarPlot
                            data={sensorStats}
                            sensorName={sensor.name ?? 'UNKNOWN'}
                            varName={activeMarker}
                          />
                        )
                      )}
                    </Grid>

                    <Grid item sm={2} xs={4} style={{ alignSelf: 'center', textAlign: 'end' }}>
                      <span
                        className={`${globalClasses.pillLabel} ${classes.occupancyPill}`}
                        style={{ background: color }}
                      >
                        {dataValueString || 'N/A'}
                        <span className={globalClasses.pillUnitLabel}>
                          {dataValueString ? varNameDetails[activeMarker].metric : ''}
                        </span>
                      </span>
                    </Grid>
                  </Grid>
                </ListItemButton>
              </ListItem>
            );
          })
        ) : (
          <Typography variant="body1">No sensor data found</Typography>
        )}
      </List>
    </div>
  );
}

export default CalendarViewSensorArray;
